<template>
<form @submit.prevent="">
    <div class="form-row">        
        <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="عنوان لیست" id="todo-name" v-model="todo.title"></v-text-field>

        <div v-if="!isMobile" class="col-12">
            <input type="text" id="todo-name" placeholder="عنوان لیست" class="widget-input input-no-bg input-lg input-set-border  w-100" v-model="todo.title">
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveChanges()" class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary rename-todo"><i class="uil uil-save"></i> ذخیره</a>
        </div>
    </div>
</form>
</template>

<script>
// این کامپوننت مودال ویرایش عنوان وظیفه می باشد
export default {
    name: 'TodoEdit',
    props: ['data'],
    components: {},
    data: function () {
        return {
            todo: {
                title: '',
            },
            isMobile:false
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            if (Object.keys(newValue).length) {
                this.loadData();
            }
        }, { immediate: true });
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        // این متد یک سری داده های اولیه را به کامپوننت تزریق می کند
        loadData() {
            this.todo = this.data.todo;
        },
        // این متد وظیفه ارسال درخواست به پرنت جهت کال بک و ذخیره سازی عنوان وظیفه را انجام می دهد
        saveChanges() {
            if (this.todo.title) {
                this.$parent.emitData('editTodo', { todo: this.todo }, true);
            }
        },
    },
}
</script>
